<template>

	<div class="content app-view" id="view-home">
		<div class="d-flex flex-column" id="information">

			<p>
				Ocean pollution is really important thing and in our opinion it’s not taken
				very seriously. It is a good initiative to combat ocean pollution by organizing
				major clean ups and cleaning up the plastic, but this will be an endless action
				if the cause of the problem isn’t addressed first. We have to change our
				lifestyle drastically.
			</p>

			<latte-ripple as="button" @click="onTakeActionTouched" class="btn btn-contained btn-light btn-pill"><span>Take action!</span></latte-ripple>

		</div>
	</div>

</template>

<script>

	export default {

		methods: {

			onTakeActionTouched()
			{
				this.$router.push("/tips");
			}

		}

	}

</script>

<style lang="scss" scoped>

	div#view-home
	{
		background:
		{
			image: url(../../public/image/take-action.jpg);
			position: left 40% bottom 30%;
			repeat: no-repeat;
			size: auto 250%;
		}

		&::before
		{
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
			background: rgba(0, 0, 0, .05);
		}
	}

	div#information
	{
		margin: auto;
		max-width: 270px;
		padding: 48px 0;
		flex-flow: column;
		color: white;
		text-align: center;
		z-index: 2;

		p
		{
			font-size: 1.2rem;
			font-weight: 500;
			text-shadow: 0 1px 2px rgba(0, 0, 0, .3);
		}

		.btn
		{
			margin-top: 48px;
		}
	}

</style>
