<template>

	<main id="app">

		<div class="app-bar app-bar-main app-bar-primary" :class="{'app-bar-transparent': !hasDocumentScrolled}" @touchend="onAppBarClick">
			<div class="app-bar-row">
				<span class="app-bar-title mx-auto">Lastic</span>
			</div>
		</div>

		<transition name="route-switch" mode="out-in">
			<router-view/>
		</transition>

		<div id="splash-screen">
			<span class="title">Lastic</span>
			<span class="tagline">Plastic lasts longer</span>
		</div>

	</main>

</template>

<script>

	import { Latte } from "@bybas/latte-ui";

	export default {

		data()
		{
			return {
				hasDocumentScrolled: false
			};
		},

		mounted()
		{
			Latte.actions.on("latte:tick", () => this.hasDocumentScrolled = document.scrollingElement.scrollTop > 0);

			setTimeout(() => this.hideSplashScreen(), 2000);
		},

		methods: {

			hideSplashScreen()
			{
				const splashScreen = this.$el.querySelector("div#splash-screen");

				splashScreen.classList.add("is-hidden");

				setTimeout(() => splashScreen.remove(), 480);
			},

			onAppBarClick()
			{
				window.scrollTo({behavior: "smooth", top: 0});
			}

		}

	}

</script>

<style lang="scss" scoped>

	.route-switch-enter-active, .route-switch-leave-active
	{
		transition: all 240ms var(--ease-swift-out);
	}

	.route-switch-enter
	{
		opacity: 0;
		transform: translate3d(48px, 0, 0);
	}

	.route-switch-leave-to
	{
		opacity: 0;
		transform: translate3d(-48px, 0, 0);
	}

	div.app-bar
	{
		transition: all 480ms var(--ease-swift-out);
	}

	span.app-bar-title
	{
		color: white;
		font-size: 1.75rem;
		text-shadow: 0 1px 2px rgba(0, 0, 0, .3);
	}

	div#splash-screen
	{
		position: fixed;
		display: flex;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		align-items: center;
		flex-flow: column;
		justify-content: center;
		background: url(../public/image/splash-screen.jpg) no-repeat center center / cover;
		color: white;
		transition: all 480ms var(--ease-swift-out);
		z-index: 10000000;

		&.is-hidden
		{
			opacity: 0;
			transform: scale3d(1.5, 1.5, 1.5);
		}

		span.title
		{
			font-size: 5rem;
			font-weight: 300;
		}

		span.tagline
		{
			font-size: 1.5rem;
			font-weight: 300;
		}
	}

</style>
