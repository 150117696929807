import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import LatteUI from "@bybas/latte-ui";

import "@mdi/font/scss/materialdesignicons.scss";
import "./assets/globals.scss";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(LatteUI);

new Vue({
	router,
	render: h => h(App)
}).$mount("#app");
